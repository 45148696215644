import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import * as React from 'react'
import theme from '../theme'
import Header from '../components/header'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import Footer from '../components/footer'
import FabNav from '../components/fabnav'

const HeadShared = ({children}) => {
    const bgQuery = useStaticQuery(graphql`
    query {
        imagesYaml(name: {eq: "BackgroundImage"}) {
            url {
              childrenImageSharp {
                gatsbyImageData
              }
            }
          }
    }
`)
    React.useEffect(() => {
      if (window)
      if (window.location.hostname === 'hu-yao.org' && 'serviceWorker' in navigator) {
        console.log('正在访问备用站点，禁用 Service Worker')
        navigator.serviceWorker.ready.then(function(registration) {
          registration.unregister()
        })
        navigator.serviceWorker.getRegistrations().then(function(registrations) {
          for (let registration of registrations) {
            registration.unregister()
          }
        })
      }
    } ,[])
      return (
        <ThemeProvider theme={theme} key="themeProvider">
            <CssBaseline/>
            <Header />
            <GatsbyImage key="background-image" image={bgQuery.imagesYaml.url.childrenImageSharp[0].gatsbyImageData} alt="background" style={{position: 'fixed', top: 0, left: 0, zIndex: -1, width: '100%', height: '100%', objectFit: 'cover'}}/>
            {children}
            <FabNav />
            <Footer />
        </ThemeProvider>
      )
  }
  
  export default HeadShared