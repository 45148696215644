import React from 'react'
import { Box, Card, CardContent, Link } from "@mui/material"
import { Foot, bluredBackgroundAppbarStyle, noLinkStyle } from "../utils/styles"

const Footer = () => {
    const beianRef = React.useRef<HTMLDivElement>(null)
    React.useEffect(() => {
        if (window) {
          if (window.location.hostname === 'hu-yao.org' || window.location.hostname === 'localhost') {
            if (beianRef.current && beianRef.current.style) {
                beianRef.current.style.display = 'none'
            }
          }
        }
    }, [])
    return (
    <Foot>
        <Card variant="outlined" sx={{ width: '100%', ...bluredBackgroundAppbarStyle }}>
            <CardContent style={{ paddingTop: '24px' }}>
                <Box display="flex" flexDirection="column">
                    <Box textAlign="center" fontWeight="bold">狐妖手作 {new Date().getFullYear()}</Box>
                    <Box textAlign="center" ref={beianRef}><Link href="https://beian.miit.gov.cn/" target="_blank" sx={noLinkStyle}>京ICP备2023017591号-3</Link></Box>
                </Box>
            </CardContent>
        </Card>
    </Foot>
    )
}

export default Footer;